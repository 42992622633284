/* eslint-disable sonarjs/cognitive-complexity, complexity */
export default defineNuxtRouteMiddleware(async (to, from) => {
  if (process.server) {
    return
  }

  const { session: currentSession, updateSession, forceLoad } = useSession()
  type Page = 'login' | 'shipping' | 'billing' | 'review' | 'thank-you'
  const page = (typeof to.meta.key === 'function' ? to.meta.key(to) : to.meta.key) as Page

  if (page === 'thank-you') {
    updateSession(await forceLoad())
    return
  }

  const { getURL } = useURL()

  const redirectToCart = () => navigateTo(getURL({ name: 'cart' }), { redirectCode: 301 })

  if (!currentSession.value) {
    redirectToCart()
  }

  const api = useEcommerce()

  const verifyResponse = await api.verifyCheckout()

  if (!verifyResponse.ok && verifyResponse.error?.__sidexp__) {
    // auto redirect in axios transform response on session expired
    return
  }
  if (!verifyResponse.ok || !verifyResponse.payload || verifyResponse.payload.session.isGuest) {
    return redirectToCart()
  }

  const { session, checkoutEnabled, shippingMethodInvalid, shippingMethodPriceChanged } = verifyResponse.payload

  if (session && session.cart) {
    updateSession({ ...session, wishlist: session.wishlist || currentSession.value.wishlist })

    if (!checkoutEnabled) {
      return redirectToCart()
    }
  } else {
    return redirectToCart()
  }

  if (session.isRegistered && page === 'login') {
    if (from && from.name?.toString().includes('checkout-shipping')) {
      return redirectToCart()
    } else {
      return navigateTo(
        getURL({
          name: 'checkout-shipping'
        })
      )
    }
  }

  if (shippingMethodInvalid && page !== 'shipping' && page !== 'login') {
    return navigateTo(
      getURL({
        name: 'checkout-shipping',
        query: { err: 'shippingMethodInvalid' }
      })
    )
  }

  if (shippingMethodPriceChanged && page !== 'shipping' && page !== 'login') {
    return navigateTo(
      getURL({
        name: 'checkout-shipping',
        query: { err: 'shippingMethodPriceChanged' }
      })
    )
  }

  if (page === 'billing' && !session.cart?.shippingAddress) {
    return redirectToCart()
  }

  if (page === 'review' && !(session.cart?.shippingAddress && session.cart?.billingAddress)) {
    return redirectToCart()
  } else if (page === 'review' && process.server) {
    return navigateTo(
      getURL({
        name: 'checkout-billing'
      })
    )
  }
})
